import { Component, OnInit } from '@angular/core';
import { AtfservicesService } from '../services/atfservices.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  regdata;
  confirmpassword;
  loading: boolean = false;
  b4loading: boolean = true;

  constructor(private atfService: AtfservicesService, private router: Router) { }

  ngOnInit() {
    this.regdata = {
      firstname: '',
      lastname: '',
      email: '',
      company_name: '',
      password: '',
      user_type: '',
      beneficiary_phonenumber: ''
    }

  }

   
  registerUser() {
    // console.log(this.regdata);
    this.loading = true;
    this.b4loading = false;

    if(this.regdata.password == this.confirmpassword) {
      this.atfService.register(this.regdata).subscribe(
        response => {
          // console.log(response)
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: 'Account Created',
            text: 'Proceed to log in',
          }).then(() => {
            this.router.navigate(['/login'])
          })
        },
        error => {
          console.log(error)

          Swal.fire({
            icon: 'error',
            title: 'Error Occured',
            text: 'Try again',
          }).then(() =>{
            window.location.reload();
          })
        }
      )
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Check Passwords',
        text: 'Passwords should match'
      }).then(() =>{
        window.location.reload();
      })
    }

  }

}