import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthserviceService } from "../services/authservice.service";
import { Role } from "../_models";
import { AtfservicesService } from '../services/atfservices.service';
import Swal from 'sweetalert2';


@Component({
  selector: "app-benviewprocessedform",
  templateUrl: "./benviewprocessedform.component.html",
  styleUrls: ["./benviewprocessedform.component.css"],
})
export class BenviewprocessedformComponent implements OnInit {

  currentUser;
  currentUserDetails;
  filledformdata;
  // bank-only view reject/approve section
  reference_number;
  ifapproved: boolean = false;
  ifrejected: boolean = false;
  bnkTradeOpsProcessedformdata;
  bnkSigOneProcessedformdata;
  bnkSigTwoProcessedformdata;
  date_processed; 

  // disable signatory areas based on the authenticated bank-user-type
  disableRejectApproveButton: boolean = false;
  disableSignatoryOneSection: boolean = false;
  disableSignatoryTwoSection: boolean = false;

  // beneficiary-only view - verify section
  loading: boolean = false;
  b4loading: boolean = true;
  otpAcquisitionBenefDetails;
  otpVerificationBenefDetails;
  claimVerificationBenefDetails;

  constructor(private authService: AuthserviceService, private atfService: AtfservicesService, private router: Router) {
    if (this.router.getCurrentNavigation().extras.state == undefined || null) {
      this.router.navigate(["/approvedforms"]);
    } else {
      this.filledformdata = this.router.getCurrentNavigation().extras.state.formData;
      console.log(this.filledformdata)
    }

    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    console.log(this.currentUser, "benview current user");
    console.log(this.filledformdata);
    this.checkTypeOfCurrentUser();

    this.reference_number = this.filledformdata.reference_number;
    this.date_processed = new Date().toDateString();

    this.bnkTradeOpsProcessedformdata = {
      reference_number: this.reference_number,
      status: "",
      status_comment: "",
    };

    this.bnkSigOneProcessedformdata = {
      reference_number: this.reference_number,
      status: "Approved at BankAuthorizedSigOneLevel",
      bank_authsignatory1_name: this.filledformdata.bank_authsignatory1_name,
      bank_authsignatory1_signature: this.filledformdata.bank_authsignatory1_signature
    }

    this.bnkSigTwoProcessedformdata = {
      reference_number: this.reference_number,
      status: "Approved",
      bank_authsignatory2_name: this.filledformdata.bank_authsignatory2_name,
      bank_authsignatory2_signature: this.filledformdata.bank_authsignatory2_signature,
      date_processed: this.date_processed
    }
    
    // for use during otp-verification
    let loggedInUserData = localStorage.getItem('currentUserDetails')
    this.currentUserDetails = JSON.parse(loggedInUserData);
    console.log('CURRENTUSERDETAILS============', this.currentUserDetails)
    // for sending the otp to benef's phone num
    this.otpAcquisitionBenefDetails={
      user_id: this.currentUserDetails.user_id,
      phone_number: this.currentUserDetails.beneficiary_phonenumber,
    }
    // console.log(' I AM HERE GETTING OTP -----------', this.otpAcquisitionBenefDetails)
    // for actually verifying the otp
    this.otpVerificationBenefDetails = {
      user_id: this.currentUserDetails.user_id,
      phone_number: this.currentUserDetails.beneficiary_phonenumber,
      otp:"",
      status:"Approved and Verified",
      reference_number: this.reference_number
      
    }
    // console.log('VERIFICATION DATA=====================', this.otpVerificationBenefDetails)
    // for making Guarantee CLAIMs 
    this.claimVerificationBenefDetails = {
      user_id: this.currentUserDetails.user_id,
      phone_number: this.currentUserDetails.beneficiary_phonenumber,
      otp:"",
      status:"Verified and Claimed",
      reference_number: this.reference_number,
      accountName: "",
      accountNumber: "",
      bankName: "",
      bankBranch:""    
    }


  }

  checkTypeOfCurrentUser() {
    if (typeof this.currentUser === "string") {
      this.currentUser = JSON.parse(this.currentUser);
      return;
    }
  }

  // getting Bank TradeOps role to enable display of doc-processing/signatory-section bit for bank-user-type
  get isBankTradeOps() {
    return this.currentUser && this.currentUser.user_type === Role.Bank_TradeOps;
  }

  // getting Bank Authorized Signatory ONE role to enable display of doc-processing/signatory-section bit for bank-user-type
  get isBankAuthorizedSignatory1() {
    this.disableSignatoryTwoSection = true;
    this.disableSignatoryOneSection = false;
    return this.currentUser && this.currentUser.user_type === Role.Bank_Authorized_Signatory_1;
  }

  // getting Bank Authorized Signatory TWO role to enable display of doc-processing/signatory-section bit for bank-user-type
  get isBankAuthorizedSignatory2() {
    this.disableSignatoryOneSection = true;
    this.disableSignatoryTwoSection = false;
    return this.currentUser && this.currentUser.user_type === Role.Bank_Authorized_Signatory_2;
  }

  // getting bank/banker role to enable display of doc-processing/signatory-section bit for bank-user-type
  get isBank() {
    return this.currentUser && this.currentUser.user_type === Role.Bank;
  }

  // getting beneficiary role to enable display of signatory section and verify-button for beneficiary-user-type
  get isBeneficiary() {
    return this.currentUser && this.currentUser.user_type === Role.Beneficiary;
  }

  // getting Super-Admin role to enable display of verify-button for Super-Admin-user-type
  get isSuperAdmin() {
    return this.currentUser && this.currentUser.user_type === Role.Super_Admin;
  }

  // bank-only view --signatory approval
  approved() {
    this.ifapproved = true;
    this.ifrejected = false;
    this.bnkTradeOpsProcessedformdata.status = "Approved at BankTradeOpsLevel";
  }

  rejected() {
    this.ifapproved = false;
    this.ifrejected = true;
    this.bnkTradeOpsProcessedformdata.status = "Rejected";
  }

  // bankLevel stageOne(BankTradeOps)ApprovalLevel
  // if rejected ---send back to applicant
  submitBackToApplicant() {
    this.atfService.banklevelformprocessingtradeopslevel(this.bnkTradeOpsProcessedformdata).subscribe(
      (response) => {
        console.log('bank-level tradeOpsLevel res: ', response)
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Form rejected :( successfully ',
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          this.router.navigate(["/formdata"]);
        });
      },
      (error) => {
        console.log('bank-level tradeOpsLevel err: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Ensure form is correctly signed",
        }).then(() => {
          window.location.reload();
        });
      }
    );
  }

  // if approved --send/forward to bankLevel stageTwo to the Bank Authorized SignatoryOne
  submitToBankAuthorizedSignatory1(){
    this.atfService.banklevelformprocessingtradeopslevel(this.bnkTradeOpsProcessedformdata).subscribe(
      (response) => {
        console.log('bank-level tradeOpsLevel res: ', response)
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Form submitted successfully',
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          this.router.navigate(["/formdata"]);
        });
      },
      (error) => {
        console.log('bank-level tradeOpsLevel err: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Ensure form is correctly signed",
        }).then(() => {
          window.location.reload();
        });
      }
    );
  }

  // bankLevel stageTwo(Bank Authorized SignatoryOne Approval) ---submitting to Bank Authorized SignatoryTwo
  submitToFinalSignatory() {
    this.atfService.banklevelformprocessingsigonelevel(this.bnkSigOneProcessedformdata).subscribe(
      (response) => {
        console.log('bank-level sigOneLevel res: ', response)
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Form submitted successfully',
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          this.router.navigate(["/formdata"]);
        });
      },
      (error) => {
        console.log('bank-level sigOneLevel err: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Ensure form is correctly signed",
        }).then(() => {
          window.location.reload();
        });
      }
    )
  }

  // bankLevel stageTwo(Bank Authorized SignatoryTwo Approval)---submitting to beneficiary
  submitToBeneficiary() {
    // console.log(this.processedformdata, 'logging data to be sent to API')
    this.atfService.banklevelformprocessingsigtwolevel(this.bnkSigTwoProcessedformdata).subscribe(
      (response) => {
        console.log('bank-level sigTWOLevel res: ', response);
        Swal.fire({
          icon: "success",
          title: "Form-Processing Completed Successfully",
          text: "Process more forms?...",
        }).then(() => {
          this.router.navigate(["/formdata"]);
        });
      },
      (error) => {
        console.log('bank-level sigTWOLevel err: ', error);
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Ensure form is correctly signed",
        }).then(() => {
          window.location.reload();
        });
      }
    );
  }
  // ============== beneficiary functions below ===============

  // beneficiary-only view --making claim
  makeGuaranteeClaim(){
    console.log('AQUIRING CLAIMING OTP ========================== ')
    this.startVerify();
  }

  finishClaiming(){
    console.log('CLAIMING BENEF INPUT DATA: ========', this.claimVerificationBenefDetails)

    this.atfService.gclaimingandverifyotpsentbybeneficiary(this.claimVerificationBenefDetails).subscribe(
      (response) => {

        if(response.code==200){
          this.loading = false;
          console.log('CLAIM AND OTP VERIFICATION SUCCESS ======YYYYYYYY=========', response)
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'Claim Successful',
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
                // goToApprovedAndVerifiedForm(data) {
                //   this.router.navigate(['/approvedandverifiedforms'], {state: {formData: data}})
                // }
                console.log('AFTER SUCCESSFULL CLAIMING ===========', this.filledformdata)
            this.router.navigate(["/verifiedandclaimedforms"], {state: {formData: this.filledformdata}});
            // this.router.navigate(["/approvedandverifiedforms"]);
          });

        }else if(response.code==400){
          Swal.fire({
            icon: "error",
            title: "Invalid OTP, retry verification",
          }).then(() => {
            window.location.reload();
          });

        } 
      },
      (error) => {
        console.log('otpAcquisitionError: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured, retry claiming / verification",
        }).then(() => {
          window.location.reload();
        });
      }
    );

  }

  // beneficiary-only view --verify button
  startVerify() {
    this.b4loading = false;
    this.loading = true;

    this.atfService.getotpfrombackend(this.otpAcquisitionBenefDetails).subscribe(
      (response) => {
        this.loading = false;
        console.log('otpAcquisition Success', response)
      },
      (error) => {
        console.log('otpAcquisitionError: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured, retry verification",
        }).then(() => {
          window.location.reload();
        });
      }
    );
  }

  cancelVerify(){
    this.b4loading = true;
    this.loading = false;
  }


  finishVerify() {
    // this.router.navigate(["/approvedandverifiedforms"])
    console.log('OTP VERIFICATION BENEF INPUT========', this.otpVerificationBenefDetails)

    this.atfService.verifyotpsentbybeneficiary(this.otpVerificationBenefDetails).subscribe(
      (response) => {

        if(response.code==200){
          this.loading = false;
          console.log('OTP VERIFICATION SUCCESS ======YYYYYYYY=========', response)
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'Form Verified',
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
                // goToApprovedAndVerifiedForm(data) {
                //   this.router.navigate(['/approvedandverifiedforms'], {state: {formData: data}})
                // }
                console.log('AFTER SUCCESSFULL VERIFICATION===========', this.filledformdata)
            this.router.navigate(["/approvedandverifiedforms"], {state: {formData: this.filledformdata}});
            // this.router.navigate(["/approvedandverifiedforms"]);
          });

        }else if(response.code==400){
          Swal.fire({
            icon: "error",
            title: "Invalid OTP, retry verification",
          }).then(() => {
            window.location.reload();
          });

        } 
      },
      (error) => {
        console.log('otpAcquisitionError: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured, retry verification",
        }).then(() => {
          window.location.reload();
        });
      }
    );
  }

  


}
