import { Component, OnInit } from '@angular/core';
import { AtfservicesService } from '../services/atfservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-formdatatable',
  templateUrl: './formdatatable.component.html',
  styleUrls: ['./formdatatable.component.css']
})
export class FormdatatableComponent implements OnInit {

  constructor(private atfService: AtfservicesService, private router:Router) { }

  formdata;
  preloadingPage:boolean=false;
  user_type;

  ngOnInit() {
    // this.fetchingUploadedFormData();
    // this.fetchingconsolidatedformdata();
    this.preloadingPage=true;

    this.user_type = localStorage.getItem('user_type')
    this.checkTypeOfCurrentUserAndDisplayRespectiveData();
  

  }

  checkTypeOfCurrentUserAndDisplayRespectiveData(){
   console.log('FORMDATA VIEW', this.user_type)
   if(this.user_type == 'Application Signatory 1'){
    //  console.log('fetching applicantLevel sigOne level data')
     this.fetchingformlistforapplicantlevelsigoneEyes();
   }
   else if(this.user_type == 'Application Signatory 2'){
    //  console.log('fetching for applicantLevel sigtwo eyes only')
     this.fetchingformlistforapplicantlevelsigtwoEyes();
   }
   else if(this.user_type == 'Bank TradeOps'){
    //  console.log('fetching for banktradeops-eyes-only')
     this.fetchingformlistforbankleveltradeopsEyes();
   }
   else if(this.user_type == 'Bank Authorized Signatory 1') {
     console.log('fetching for Bank Authorized Signatory 1 eyes only')
     this.fetchingformlistforbanklevelsigOneEyes();
   }
   else if(this.user_type == 'Bank Authorized Signatory 2') {
     console.log('fetching for Bank Authorized Signatory 2 eyes only')
     this.fetchingformlistforbanklevelsigTwoEyes();
   }
   else if(this.user_type == 'Super-Admin') {
     this.fetchingconsolidatedformdata()
     return;
   }
   
  }

  // fetching all processed forms (pending,processing,rejected and approved) --- a combination of guaranteeapplication-forms table and processed-forms table
  // for superadmin view
  fetchingconsolidatedformdata(){
    this.atfService.getconsolidatedformdata().subscribe(
      response => {
        this.preloadingPage=false;
        // console.log(response)
        this.formdata = response
        // console.log(this.formdata)
      },
      error => {
        console.log(error)
      }
    )
  }

  // get pending forms yet to be approved as well as forms approved by applicant-level signatoryOne   ---for applicant-level signatory-one view
  fetchingformlistforapplicantlevelsigoneEyes(){
    this.atfService.getapplicantlevelsigoneformslist().subscribe(
      response => {
        this.preloadingPage=false;
        // console.log(response)
        this.formdata = response
        // console.log(this.formdata)
      },
      error => {
        console.log(error)
      }
    )
  }

  // fetching forms that have been approved at applicant-level by signatory one ---to be viewed by applicantLevelSigTwo
  fetchingformlistforapplicantlevelsigtwoEyes(){
    this.atfService.getapplicantlevelsigtwoformslist().subscribe(
      response => {
        this.preloadingPage=false;
        // console.log(response)
        this.formdata = response
        // console.log(this.formdata)
      },
      error => {
        console.log(error)
      }
    )
  }

  // fetching forms that have been approved at applicant-level by signatory two ---to be viewed by Bnk-Level BnkTradeOps
  fetchingformlistforbankleveltradeopsEyes(){
    this.atfService.getbankleveltradeopsoformslist().subscribe(
      response => {
        this.preloadingPage=false;
        // console.log(response)
        this.formdata = response
        // console.log(this.formdata)
      },
      error => {
        console.log(error)
      }
    )
  }

  // fetching forms that have been approved at bank-level by tradeOps ---to be viewed by Bnk-Level BankAuthorizedSigONE
  fetchingformlistforbanklevelsigOneEyes(){
    this.atfService.getbanklevelsigoneformslist().subscribe(
      response => {
        this.preloadingPage=false;
        // console.log(response)
        this.formdata = response
        // console.log(this.formdata)
      },
      error => {
        console.log(error)
      }
    )
  }

  // fetching forms that have been approved at bank-level by BnkAuthSigONE ---to be viewed by Bnk-Level BankAuthorizedSigTWO
  fetchingformlistforbanklevelsigTwoEyes(){
    this.atfService.getbanklevelsigtwoformslist().subscribe(
      response => {
        this.preloadingPage=false;
        console.log(response)
        this.formdata = response
        console.log(this.formdata)
      },
      error => {
        console.log(error)
      }
    )
  }

  goToFilledForm(data) {
    this.router.navigate(['/bankviewfilledform'], {state: {formData: data}})
  }

  goToFilledDoc(data) {
    this.router.navigate(['/benviewapproved-forms'], {state: {formData: data}})
  }

}
