import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AtfservicesService {

  constructor(private http: HttpClient) { }

  
  register(regdata): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/register`, regdata )
  }

// login ==> go to authservice.ts

  
  uploadformdata(formdata): Observable<any> {

    return this.http.post(`${environment.apiUrl}/api/formdata-upload`, formdata)
  }

 
  getuploadedformdata(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/api/application-forms`)
  }

  // getting all applications(pending/approved/rejected) for a single user/applicant
  getsingleuserapplications(user_id): Observable<any> {
    
    let formatted_user_id = JSON.stringify(user_id)
    return this.http.get(`${environment.apiUrl}/api/my-applications/`+formatted_user_id)
  }

  // updating filled form with APPLICANT-LEVEL level-one SIGNATORY ONE DATA
  applicantleveloneformprocessing(processedformdata): Observable<any> {
    console.log('at service ================ ', processedformdata)
    
    return this.http.post(`${environment.apiUrl}/api/formprocessing`, processedformdata)
  }

  // updating filled form with APPLICANT-LEVEL level-two (SIGNATORY TWO) DATA
  applicantleveltwoformprocessing(processedformdata): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/formprocessing/applicantlevelsigtwo`, processedformdata)
  }

  // updating filled form with BANK-LEVEL level-one (Bank TradeOps) DATA
  banklevelformprocessingtradeopslevel(processedformdata): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/bankformprocessing/bankleveltradeops`, processedformdata)
  }

  // updating filled form with BANK-LEVEL level-two (Bank Authorized SignatoryOne) DATA
  banklevelformprocessingsigonelevel(processedformdata): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/bankformprocessing/banklevelsigone`, processedformdata)
  }

  // updating filled form with BANK-LEVEL level-three/finalLevel (Bank Authorized SignatoryTwo) DATA
  banklevelformprocessingsigtwolevel(processedformdata): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/bankformprocessing/banklevelsigtwo`, processedformdata)
  }

 
  // get consolidated data from 2 tables applicationforms table and processed forms table --superadmin view
  getconsolidatedformdata() {

    return this.http.get(`${environment.apiUrl}/api/processed-formdata`)
  }
  
  // get pending forms yet to be approved as well as forms approved by applicant-level signatoryOne   ---for applicant-level signatory-one view
  getapplicantlevelsigoneformslist(){

    return this.http.get(`${environment.apiUrl}/api/applicant-level/sigone/pendingorapproved-forms`)
  }

  // fetching forms that have been approved at applicant-level by signatory one ---to be viewed by applicantLevelSigTwo
  getapplicantlevelsigtwoformslist(){

    return this.http.get(`${environment.apiUrl}/api/applicant-level/sigone/approved-forms`)
  }

  // fetching forms that have been approved at applicant-level by signatory two ---to be viewed by Bnk-Level BnkTradeOps
  getbankleveltradeopsoformslist(){

    return this.http.get(`${environment.apiUrl}/api/applicant-level/sigtwo/approved-forms`)
  }

  // fetching forms that have been approved at bank-level by tradeOps ---to be viewed by Bnk-Level BankAuthorizedSigONE
  getbanklevelsigoneformslist(){

    return this.http.get(`${environment.apiUrl}/api/bank-level/tradeops/approved-forms`)
  }

  // fetching forms that have been approved at bank-level by BnkAuthSigONE ---to be viewed by Bnk-Level BankAuthorizedSigTWO
  getbanklevelsigtwoformslist(){

    return this.http.get(`${environment.apiUrl}/api/bank-level/sigone/approved-forms`)
  }

  // fetching forms that have been completely processed and approved at bank-level by finalSignatory i.e. BankAuthorizedSigTWO  ---to finally be viewed by the Beneficiary [ALL BENEFS regardless of the phone number]
  getapprovedformslist(){

    return this.http.get(`${environment.apiUrl}/api/approved-forms`)
  }

// fetching only completly approved-forms ie approved by Bank Authorized Signatory Two. This is to be viewed by SPECIFIC/SINGLE BENEFiciary based on their respective beneficiary phone_number
  getapprovedformslistforsinglebenef(benefPhoneNum): Observable<any> {

    console.log('at service single benef phne num', benefPhoneNum)

    return this.http.post(`${environment.apiUrl}/api/single-benef/approved-forms`, benefPhoneNum)
  }


  // (start otp verification) get otp from twilio/db ---sending otp to currently-logged-in beneficiary's phone number
  getotpfrombackend(otpVerificationData): Observable<any> {

    return this.http.post(`${environment.apiUrl}/api/get-verification-otp`, otpVerificationData)
  }

  // (finish otp verification) confirm/verifying otp sent by beneficiary
  verifyotpsentbybeneficiary(otpVerificationData): Observable<any> {
    
    console.log('FINISHING VERIFICATION ==========================', otpVerificationData)

    return this.http.post(`${environment.apiUrl}/api/verify-otp`, otpVerificationData)
  }

  // (finish CLAIMING and otp verification) confirm/verifying otp sent by beneficiary
  gclaimingandverifyotpsentbybeneficiary(gclaimotpVerificationData): Observable<any> {
  
    console.log('FINISHING CLAIMING ==========================', gclaimotpVerificationData)

    return this.http.post(`${environment.apiUrl}/api/claiming-and-verify-otp`, gclaimotpVerificationData)
  }

  // Fetching Claimed and Verified form based on g-form-ref_num
  fetchingclaimedandverifiedform(gformrefnum): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/verified-and-claimed-form`, gformrefnum)
  }
   
}
