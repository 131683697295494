import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {

    // let userData=localStorage.getItem('currentUser');
    // if(userData != undefined) {
    //   const JSONString = userData;
    //   let object = JSON.parse(JSONString);
    //   const array = Object.keys(object).map(function(k) {
    //       return object[k];
    //     });
    //     console.log('authservicearray==========', array)

    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(JSON.stringify(array)));
    // this.currentUser = this.currentUserSubject.asObservable();

    // }

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(JSON.stringify(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();

  }

   public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  
login(logindata): Observable<any> {

  return this.http.post<any>(`${environment.apiUrl}/api/login`, logindata).pipe(map(response => {
    // login successful
    if (response) {

      // console.log('AUTHSERVICE.TS LOG IN RESPONSE=============', response)

      if(response.results) {
        // console.log('AUTHSERVICE.TS LOG IN RESPONSE.RESULTS[0]=============', JSON.stringify(response.results[0]))

        localStorage.setItem('currentUser', JSON.stringify(response.results[0]));
        
        this.currentUserSubject.next(response.results[0]);
        return response;
      }
      
      
    }
    return response;
    
  }));

}


logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
