// @angular modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

// angular apps
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// bootstrap modules
import { MDBBootstrapModule } from 'angular-bootstrap-md';

// Interceptors
import { JwtInterceptor, ErrorInterceptor } from './_helpers';

// components
import { NavbarComponent } from './navbar/navbar.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormdatatableComponent } from './formdatatable/formdatatable.component';
import { BankviewfilledformComponent } from './bankviewfilledform/bankviewfilledform.component';
import { BenviewprocessedformComponent } from './benviewprocessedform/benviewprocessedform.component';
import { BenviewapprovedlistComponent } from './benviewapprovedlist/benviewapprovedlist.component';
import { ApplicantfilledformsComponent } from './applicantfilledforms/applicantfilledforms.component';
import { MypreloaderComponent } from './mypreloader/mypreloader.component';
import { SuperadminloginComponent } from './superadminlogin/superadminlogin.component';
import { ApplicationguidelinesComponent } from './applicationguidelines/applicationguidelines.component';
import { TncsComponent } from './tncs/tncs.component';
import { ApprovedandverifiedformComponent } from './approvedandverifiedform/approvedandverifiedform.component';
import { VerifiedandclaimedformComponent } from './verifiedandclaimedform/verifiedandclaimedform.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FileuploadComponent,
    LoginComponent,
    RegisterComponent,
    FormdatatableComponent,
    BankviewfilledformComponent,
    BenviewprocessedformComponent,
    BenviewapprovedlistComponent,
    ApplicantfilledformsComponent,
    MypreloaderComponent,
    SuperadminloginComponent,
    ApplicationguidelinesComponent,
    TncsComponent,
    ApprovedandverifiedformComponent,
    VerifiedandclaimedformComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
