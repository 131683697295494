import { Component, OnInit } from "@angular/core";
import { AtfservicesService } from "../services/atfservices.service";
import { AuthserviceService } from "../services/authservice.service";
import { Router } from "@angular/router";
import { Role } from "../_models";
import Swal from "sweetalert2";
// import AWS from "aws-sdk";
import * as AWS from 'aws-sdk';


@Component({
  selector: "app-fileupload",
  templateUrl: "./fileupload.component.html",
  styleUrls: ["./fileupload.component.css"],
})
export class FileuploadComponent implements OnInit {
  uuid;
  uniquerefnum;

  currentUser;

  // std vs non-std gtype
  isStdGForm: boolean = true;
  isNonStdGForm: boolean = false;

  // uploading file pdf/img
  // fileToUpload;
  fileName;
  fileType;
  fileUrl;

  // section one
  applicantTypeisSelected: boolean = false;
  isSelfApplicant: boolean = true;

  // section two
  benef2buttonName = "Add";
  addBen2: boolean = true;
  benef3buttonName = "Add";
  addBen3: boolean = true;
  ben1name;
  ben1tel;
  ben1email;
  ben2name;
  ben2tel;
  ben2email;
  ben3name;
  ben3tel;
  ben3email;

  // section 3
  disableadviseorreissueradiobutton: boolean = true;

  // section 4
  // looping radio buttons
  guaranteeTypes;
  currencies;
  disableothergtypeinput: boolean = true;

  // section 5
  isHardCopy: boolean;
  disablebnkcountercollectinput: boolean = true;
  disablebranchcollectinput: boolean = true;
  disableindividualcollectorinput = true;
  disablelocalagentcollectorinput = true;
  disablebenefcollectorinput = true;

  // section 6
  disablerulesinput1: boolean = true;
  disablerulesinput2: boolean = true;

  // TsnCs
  viewTsnCs: boolean = false;
  tncsLinkName = "View T&Cs";

  // form submission
  formdata;
  loading: boolean = false;
  b4loading: boolean = true;

  constructor(
    private authService: AuthserviceService,
    private atfService: AtfservicesService,
    private router: Router
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.checkTypeOfCurrentUser();
    this.disableadviseorreissueradiobutton = true;

    this.uuid = localStorage.getItem("uuid");
    // console.log(this.uuid)

    let myUniqueRefNum =
      "GA-" +
      Math.random().toString().substr(2, 10) +
      "-ATF" +
      this.uuid.toString().substr(4, 5) +
      this.uuid.toString().substr(-4, 4);
    this.uniquerefnum = myUniqueRefNum.toUpperCase();
    // console.log(this.uniquerefnum);

    // guarantee types --looping radio buttons
    this.guaranteeTypes = [
      "Bid Bond",
      "Tender Guarantee",
      "Custom Bond",
      "Performance Guarantee",
      "Security Bond",
      "Advance Payment Guarantee",
      "Shipping Guarantee",
      "General Bank Guarantee",
      "Retention Guarantee",
      "SLBC",
    ];

    this.currencies = [
        "AFN: Afghani",
        "EUR: Euro",
        "ALL: Lek",
        "DZD: Algerian Dinar",
        "USD: US Dollar",
        "AOA: Kwanza",
        "XCD: East Caribbean Dollar",
        "ARS: Argentine Peso",
        "AMD: Armenian Dram",
        "AWG: Aruban Florin",
        "AUD: Australian Dollar",
        "AZN: Azerbaijanian Manat",
        "BSD: Bahamian Dollar",
        "BHD: Bahraini Dinar",
        "BDT: Taka",
        "BBD: Barbados Dollar",
        "BYR: Belarusian Ruble",
        "BZD: Belize Dollar",
        "XOF: CFA Franc BCEAO",
        "BMD: Bermudian Dollar",
        "INR: Indian Rupee",
        "BTN: Ngultrum",
        "BOB: Boliviano",
        "BOV: Mvdol",
        "BAM: Convertible Mark",
        "BWP: Pula",
        "NOK: Norwegian Krone",
        "BRL: Brazilian Real",
        "BND: Brunei Dollar",
        "BGN: Bulgarian Lev",
        "BIF: Burundi Franc",
        "CVE: Cabo Verde Escudo",
        "KHR: Riel",
        "XAF: CFA Franc BEAC",
        "CAD: Canadian Dollar",
        "KYD: Cayman Islands Dollar",
        "CLP: Chilean Peso",
        "CLF: Unidad de Fomento",
        "CNY: Yuan Renminbi",
        "COP: Colombian Peso",
        "COU: Unidad de Valor Real",
        "KMF: Comoro Franc",
        "CDF: Congolese Franc",
        "NZD: New Zealand Dollar",
        "CRC: Costa Rican Colon",
        "HRK: Kuna",
        "CUP: Cuban Peso",
        "CUC: Peso Convertible",
        "ANG: Netherlands Antillean Guilder",
        "CZK: Czech Koruna",
        "DKK: Danish Krone",
        "DJF: Djibouti Franc",
        "DOP: Dominican Peso",
        "EGP: Egyptian Pound",
        "SVC: El Salvador Colon",
        "ERN: Nakfa",
        "ETB: Ethiopian Birr",
        "FKP: Falkland Islands Pound",
        "FJD: Fiji Dollar",
        "XPF: CFP Franc",
        "GMD: Dalasi",
        "GEL: Lari",
        "GHS: Ghana Cedi",
        "GIP: Gibraltar Pound",
        "GTQ: Quetzal",
        "GBP: Pound Sterling",
        "GNF: Guinea Franc",
        "GYD: Guyana Dollar",
        "HTG: Gourde",
        "HNL: Lempira",
        "HKD: Hong Kong Dollar",
        "HUF: Forint",
        "ISK: Iceland Krona",
        "IDR: Rupiah",
        "XDR: SDR (Special Drawing Right)",
        "IRR: Iranian Rial",
        "IQD: Iraqi Dinar",
        "ILS: New Israeli Sheqel",
        "JMD: Jamaican Dollar",
        "JPY: Yen",
        "JOD: Jordanian Dinar",
        "KZT: Tenge",
        "KES: Kenyan Shilling",
        "KPW: North Korean Won",
        "KRW: Won",
        "KWD: Kuwaiti Dinar",
        "KGS: Som",
        "LAK: Kip",
        "LBP: Lebanese Pound",
        "LSL: Loti",
        "ZAR: Rand",
        "LRD: Liberian Dollar",
        "LYD: Libyan Dinar",
        "CHF: Swiss Franc",
        "MOP: Pataca",
        "MKD: Denar",
        "MGA: Malagasy Ariary",
        "MWK: Malawi Kwacha",
        "MYR: Malaysian Ringgit",
        "MVR: Rufiyaa",
        "MRO: Ouguiya",
        "MUR: Mauritius Rupee",
        "XUA: ADB Unit of Account",
        "MXN: Mexican Peso",
        "MXV: Mexican Unidad de Inversion (UDI)",
        "MDL: Moldovan Leu",
        "MNT: Tugrik",
        "MAD: Moroccan Dirham",
        "MZN: Mozambique Metical",
        "MMK: Kyat",
        "NAD: Namibia Dollar",
        "NPR: Nepalese Rupee",
        "NIO: Cordoba Oro",
        "NGN: Naira",
        "OMR: Rial Omani",
        "PKR: Pakistan Rupee",
        "PAB: Balboa",
        "PGK: Kina",
        "PYG: Guarani",
        "PEN: Sol",
        "PHP: Philippine Peso",
        "PLN: Zloty",
        "QAR: Qatari Rial",
        "RON: Romanian Leu",
        "RUB: Russian Ruble",
        "RWF: Rwanda Franc",
        "SHP: Saint Helena Pound",
        "WST: Tala",
        "STD: Dobra",
        "SAR: Saudi Riyal",
        "RSD: Serbian Dinar",
        "SCR: Seychelles Rupee",
        "SLL: Leone",
        "SGD: Singapore Dollar",
        "XSU: Sucre",
        "SBD: Solomon Islands Dollar",
        "SOS: Somali Shilling",
        "SSP: South Sudanese Pound",
        "LKR: Sri Lanka Rupee",
        "SDG: Sudanese Pound",
        "SRD: Surinam Dollar",
        "SZL: Lilangeni",
        "SEK: Swedish Krona",
        "CHE: WIR Euro",
        "CHW: WIR Franc",
        "SYP: Syrian Pound",
        "TWD: New Taiwan Dollar",
        "TJS: Somoni",
        "TZS: Tanzanian Shilling",
        "THB: Baht",
        "TOP: Pa’anga",
        "TTD: Trinidad and Tobago Dollar",
        "TND: Tunisian Dinar",
        "TRY: Turkish Lira",
        "TMT: Turkmenistan New Manat",
        "UGX: Uganda Shilling",
        "UAH: Hryvnia",
        "AED: UAE Dirham",
        "UYU: Peso Uruguayo",
        "UYI: Uruguay Peso en Unidades Indexadas (URUIURUI)",
        "UZS: Uzbekistan Sum",
        "VUV: Vatu",
        "VEF: Bolívar",
        "VND: Dong",
        "YER: Yemeni Rial",
        "ZMW: Zambian Kwacha",
        "ZWL: Zimbabwe Dollar"
    ]

    this.formdata = {
      user_id: "",
      reference_number: this.uniquerefnum,
      file_upload_name: "",
      file_upload_type: "",
      file_upload_url:"",
      applicant_name: "",
      applicant_postal_address: "",
      proxy_applicant_name: "",
      proxy_applicant_address: "",
      applicant_tel_number: "",
      applicant_email: "",
      applicant_rel_wcustomer: "",
      beneficiarycorporatename: "",
      beneficiarycorporateaddress: "",
      beneficiary1_name: "",
      beneficiary1_postal_address: "",
      beneficiary1_tel_number: "",
      beneficiary1_email: "",
      beneficiary2_name: "",
      beneficiary2_postal_address: "",
      beneficiary2_tel_number: "",
      beneficiary2_email: "",
      beneficiary3_name: "",
      beneficiary3_postal_address: "",
      beneficiary3_tel_number: "",
      beneficiary3_email: "",
      insurance_manner_ps: "",
      insurance_manner_ar: "",
      reissuing_bank_address: "",
      currencyType: "",
      guarantee_amount_num: "",
      guarantee_amount_words: "",
      guarantee_type: "",
      other_guarantee_type: "",
      guarantee_purpose: "",
      validity_from: "",
      validity_to: "",
      counter_guarantee_expiry: "",
      delivery_type: "",
      deltype_bnk_trd_counter_info: "",
      deltype_branch_info: "",
      collectedby_info: "",
      local_agent_name: "",
      local_agent_tel: "",
      collectedbybeneficiary_name: "",
      collectedbybeneficiary_tel: "",
      collectedby_id_or_passport: "",
      applicable_rules: "",
      applicable_rules_URDG_or_kenlaw_info: "",
      applicable_rules_otherlaws_info: "",
    };
  }

  checkTypeOfCurrentUser() {
    if (typeof this.currentUser === "string") {
      this.currentUser = JSON.parse(this.currentUser);
      return;
    }
  }

  // --- old version getting signatory role to enable display of form-processing bit for signatory-user-type
  get isSignatory() {
    return this.currentUser && this.currentUser.user_type === Role.Signatory;
  }

  // getting APPLICANT-LEVEL signatory one role to enable display of form-processing bit for signatory-user-type
  get isApplicantSignatory1() {
    return this.currentUser && this.currentUser.user_type === Role.Application_Signatory_1;
  }

  // getting APPLICANT-LEVEL signatory two role to enable display of form-processing bit for signatory-user-type
  get isApplicantSignatory2() {
    return this.currentUser && this.currentUser.user_type === Role.Application_Signatory_2;
  }

  // getting Super-Admin role to enable display of form-processing bit for Super-Admin-user-type
  get isSuperAdmin() {
    return this.currentUser && this.currentUser.user_type === Role.Super_Admin;
  }

  // std vs non-std g-form
  isStdGType() {
    this.isStdGForm = true;
    this.isNonStdGForm = false;
  }

  isNonStdGType() {
    this.isStdGForm = false;
    this.isNonStdGForm = true;
    this.formdata.proxy_applicant_name = "";
    this.formdata.proxy_applicant_address = "";
    this.formdata.applicant_rel_wcustomer = "";
    this.formdata.applicant_name = "";
    this.formdata.applicant_postal_address = "";
    this.formdata.applicant_tel_number = "";
    this.formdata.applicant_email = "";
    this.formdata.guarantee_amount_num = "";
    this.formdata.guarantee_amount_words = "";
    this.formdata.guarantee_purpose = "";
    this.formdata.validity_from = "";
    this.formdata.validity_to = "";
    this.formdata.counter_guarantee_expiry = "";
    this.formdata.applicable_rules = "";
    this.formdata.applicable_rules_URDG_or_kenlaw_info = "";
    this.formdata.applicable_rules_otherlaws_info = "";
  }

  // section 1 functions
  uploadFileToS3AndGetFileUrl($event){
    const file = $event.target.files[0];
    console.log('-------my file------------',file)
    this.fileName = file.name
    this.fileType = file.type
    // var self = this;
      const s3 = new AWS.S3({
          accessKeyId:"AKIAQNAWSXOEZE77O2FI",
          secretAccessKey: "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"
      });
        const params = {
            Bucket: "tradefinancefileupload",
            Key: file.name, // File name you want to save as in S3
            ACL: 'public-read',
            Body: file
        };
        var s3UploadPromise = new Promise(function(resolve, reject) {

            s3.upload(params, function(err, data) {
                if (err) {
                    reject(err);
                    // return err;
                } else {
                  // return data;
                 

                    resolve(data);
                }
            });
        });
        console.log('s3 upload promise: ==========================',s3UploadPromise)
        s3UploadPromise.then(v => {
          const newObj: any = v;
          // return newObj.Location;

          console.log('000---', newObj.Location)
          this.fileUrl = newObj.Location
        })
  }


  isCustomerApplicant() {
    this.formdata.proxy_applicant_name = "N/A";
    this.formdata.proxy_applicant_address = "N/A";
    this.formdata.applicant_rel_wcustomer = "N/A";
    this.isSelfApplicant = true;
    this.applicantTypeisSelected = true;
  }

  isProxyApplicant() {
    this.formdata.proxy_applicant_name = "";
    this.formdata.proxy_applicant_address = "";
    this.formdata.applicant_rel_wcustomer = "";
    this.formdata.applicant_name = "";
    this.formdata.applicant_postal_address = "";
    this.formdata.applicant_tel_number = "";
    this.formdata.applicant_email = "";
    this.isSelfApplicant = false;
    this.applicantTypeisSelected = true;
  }

  // section 2 functions
  addRemoveBen2() {
    this.addBen2 = !this.addBen2;

    if (this.addBen2) {
      this.benef2buttonName = "Add";
      // replace fields with N/A if user removes additional beneficiary
      this.formdata.beneficiary2_name = "N/A";
      this.formdata.beneficiary2_postal_address = "";
      this.formdata.beneficiary2_tel_number = "";
      this.formdata.beneficiary2_email = "";
    } else {
      this.benef2buttonName = "Remove";
    }
  }

  addRemoveBen3() {
    this.addBen3 = !this.addBen3;

    if (this.addBen3) {
      this.benef3buttonName = "Add";
      // replace fields with N/A if user removes additional beneficiary
      this.formdata.beneficiary3_name = "N/A";
      this.formdata.beneficiary3_postal_address = "";
      this.formdata.beneficiary3_tel_number = "";
      this.formdata.beneficiary3_email = "";
    } else {
      this.benef3buttonName = "Remove";
    }
  }

  onBen1Name(event: any) {
    this.ben1name = event.target.value;
  }

  onBen1Tel(event: any) {
    this.ben1tel = event.target.value;
  }

  onBen1Email(event: any) {
    this.ben1email = event.target.value;
  }

  onBen2Name(event: any) {
    this.ben2name = event.target.value;
  }

  onBen2Tel(event: any) {
    this.ben2tel = event.target.value;
  }

  onBen2Email(event: any) {
    this.ben2email = event.target.value;
  }

  onBen3Name(event: any) {
    this.ben3name = event.target.value;
  }

  onBen3Tel(event: any) {
    this.ben3tel = event.target.value;
  }

  onBen3Email(event: any) {
    this.ben3email = event.target.value;
  }

  // section 3
  isPaper() {
    this.disableadviseorreissueradiobutton = true;
  }
  isSwift(){
    this.disableadviseorreissueradiobutton = false;
  }

  // section 4
  enableothergtypeinput() {
    this.disableothergtypeinput = false;
  }
  redisableothergtypeinput() {
    this.disableothergtypeinput = true;
    this.formdata.other_guarantee_type = "";
  }

  // section 5
  physicalDelivery() {
    this.isHardCopy = true;
  }

  onlineDelivery() {
    // hide hard-copy fields
    this.isHardCopy = false;
    // empty hard-copy fields
    this.formdata.delivery_type = "";
    this.formdata.deltype_bnk_trd_counter_info = "";
    this.formdata.deltype_branch_info = "";
    this.formdata.collectedby_info = "";
    this.formdata.local_agent_name = "";
    this.formdata.local_agent_tel = "";
    this.formdata.collectedbybeneficiary_name = "";
    this.formdata.collectedbybeneficiary_tel = "";
    this.formdata.collectedby_id_or_passport = "";
  }

  collectingfrombnkcounter() {
    this.disablebnkcountercollectinput = false;
    this.disablebranchcollectinput = true;
    this.formdata.deltype_branch_info = "";
  }

  collectingfrmbranch() {
    this.disablebranchcollectinput = false;
    this.disablebnkcountercollectinput = true;
    this.formdata.delivery_type = "";
    this.formdata.deltype_bnk_trd_counter_info = "";
  }

  individualcolletor() {
    this.disableindividualcollectorinput = false;
    this.disablelocalagentcollectorinput = true;
    this.disablebenefcollectorinput = true;
    this.formdata.local_agent_name = "";
    this.formdata.local_agent_tel = "";
    this.formdata.collectedbybeneficiary_name = "";
    this.formdata.collectedbybeneficiary_tel = "";
    this.formdata.collectedby_id_or_passport = "";
  }

  localagentcollector() {
    this.disableindividualcollectorinput = true;
    this.disablelocalagentcollectorinput = false;
    this.disablebenefcollectorinput = true;
    this.formdata.collectedby_info = "";
    this.formdata.collectedbybeneficiary_name = "";
    this.formdata.collectedbybeneficiary_tel = "";
    this.formdata.collectedby_id_or_passport = "";
  }

  beneficiarycollector() {
    this.disableindividualcollectorinput = true;
    this.disablelocalagentcollectorinput = true;
    this.disablebenefcollectorinput = false;
    this.formdata.collectedby_info = "";
    this.formdata.local_agent_name = "";
    this.formdata.local_agent_tel = "";
    this.formdata.collectedbybeneficiary_name = "";
  }

  // section 6
  enablerulesinput1() {
    this.disablerulesinput1 = false;
    this.disablerulesinput2 = true;
    this.formdata.applicable_rules_otherlaws_info = "";
  }

  enablerulesinput2() {
    this.disablerulesinput1 = true;
    this.disablerulesinput2 = false;
    this.formdata.applicable_rules_URDG_or_kenlaw_info = "";
  }



  // TsnCs
  showTsnCs() {
    this.viewTsnCs = !this.viewTsnCs;
    if (this.viewTsnCs) {
      this.tncsLinkName = "Hide";
    } else {
      this.tncsLinkName = "View T&Cs";
    }
  }

  // form submission
  uploadFormData() {
    this.loading = true;
    this.b4loading = false;
    this.formdata.user_id = this.uuid;
    this.formdata.file_upload_name =  this.fileName;
    this.formdata.file_upload_type = this.fileType;
    this.formdata.file_upload_url = this.fileUrl;
   
    console.log("logging full formdata", this.formdata);
    console.log('I AM HERE===========================')
    this.atfService.uploadformdata(this.formdata).subscribe(
      (response) => {
        // console.log(response);
        Swal.fire({
          icon: "success",
          title: "Form-Data Uploaded Successfully",
          text: "See what you have uploaded",
        }).then(() => {
          this.router.navigate(["/my-applications"]);
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Try again",
        }).then(() => {
          window.location.reload();
        });
      }
    );
  }
}
