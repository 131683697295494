import { Component, OnInit } from '@angular/core';
import { AtfservicesService } from '../services/atfservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-benviewapprovedlist',
  templateUrl: './benviewapprovedlist.component.html',
  styleUrls: ['./benviewapprovedlist.component.css']
})
export class BenviewapprovedlistComponent implements OnInit {

  constructor(private atfService: AtfservicesService, private router:Router) { }

  formdata;
  currentlyLoggedInBenefPhoneNum = "";
  currentBenefDetails;


  ngOnInit() {
    // this.fetchingapprovedformslist();
    this.fetchingapprovedformslistforsinglebenef();
    // to be used to get current-User/beneficiary's phone number
    
    
  }

  // Depricated === or can be used for Super-Admin View
  // fetching consolidateddata guaranteeapplication-forms table and processed-forms table WHERE STATUS = APPROVED
  // fetchingapprovedformslist(){
  //   this.atfService.getapprovedformslist().subscribe(
  //     response => {
  //       // console.log(response)
  //       this.formdata = response
  //     },
  //     error => {
  //       console.log(error)
  //     }
  //   )
  // }



// updated API below
// fetching consolidateddata guaranteeapplication-forms table and processed-forms table WHERE STATUS == APPROVED and phone_number == phone_number of the currenlty-logged-in beneficiary
fetchingapprovedformslistforsinglebenef(){

  let loggedInUserData = localStorage.getItem('currentUserDetails')
  this.currentBenefDetails = JSON.parse(loggedInUserData);
  // console.log('CURRENTBENEFDETAILS=====', this.currentBenefDetails)
  this.currentlyLoggedInBenefPhoneNum = this.currentBenefDetails.beneficiary_phonenumber;
  // console.log('CURRENT BENEF PHONE NUM', this.currentlyLoggedInBenefPhoneNum)

  let toSendToAPI = {
    "beneficiary_phonenumber": this.currentlyLoggedInBenefPhoneNum
  }

  
  this.atfService.getapprovedformslistforsinglebenef(toSendToAPI).subscribe(
    response => {
      console.log(response)
      this.formdata = response
    },
    error => {
      console.log(error)
    }
  )
}

goToFilledForm(data) {
  this.router.navigate(['/benviewapproved-forms'], {state: {formData: data}})
}

}
