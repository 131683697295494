export enum Role {
    // application level
    Guarantee_Applicant = 'Guarantee Applicant',
    Signatory = 'Signatory', //this was deprecated after two separate/unique signatories were introduced
    Application_Signatory_1 = 'Application Signatory 1',
    Application_Signatory_2 = 'Application Signatory 2',
    
    // bank level
    Bank = 'Bank', //this was deprecated after three separate/unique signatories were introduced
    Bank_TradeOps = 'Bank TradeOps',
    Bank_Authorized_Signatory_1 = 'Bank Authorized Signatory 1',
    Bank_Authorized_Signatory_2 = 'Bank Authorized Signatory 2',

    // beneficiary level
    Beneficiary = 'Beneficiary',
    Super_Admin = 'Super-Admin'
}