import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthserviceService } from "../services/authservice.service";
import { Role } from "../_models";
import { AtfservicesService } from '../services/atfservices.service';

@Component({
  selector: 'app-verifiedandclaimedform',
  templateUrl: './verifiedandclaimedform.component.html',
  styleUrls: ['./verifiedandclaimedform.component.css']
})
export class VerifiedandclaimedformComponent implements OnInit {

  filledformdata;
  refNum;
  filledformdatawithbankdetails;
  currentUser;
  currentUserDetails;
  date_approved_and_verified; 

  constructor(private authService: AuthserviceService, private atfService: AtfservicesService, private router: Router) {

    if (this.router.getCurrentNavigation().extras.state == undefined || null) {
      // console.log('I AM HERE ONE XXXXXXXX===========================')
      this.router.navigate(["/approvedforms"]);
    } else {
      // console.log('I AM HERE TWO YYYYYY===========================')
      this.filledformdata = this.router.getCurrentNavigation().extras.state.formData;
      console.log('DATA AT VERIFIED AND CLAIMED FORM PAGE: ===========================', this.filledformdata)
    }

    this.currentUser = this.authService.currentUserValue;
   }

  ngOnInit() {
    console.log(this.currentUser, "benview current user");
    console.log('ON INIT DATA AT VERIFIED AND CLAIMED FORM PAGE ON INIT', this.filledformdata);
    this.refNum = this.filledformdata.reference_number;
    console.log('CURRENT REF NUM ON INIT', this.refNum)
    this.checkTypeOfCurrentUser();
    this.date_approved_and_verified = new Date().toDateString();
    let loggedInUserData = localStorage.getItem('currentUserDetails')
    this.currentUserDetails = JSON.parse(loggedInUserData);
    this.getClaimedAndVerifiedFormData();
  }

  checkTypeOfCurrentUser() {
    if (typeof this.currentUser === "string") {
      this.currentUser = JSON.parse(this.currentUser);
      return;
    }
  }

  getClaimedAndVerifiedFormData(){
    console.log('CURRENT REF NUM AT FETCHING DATA', this.refNum)
    let toSendToAPI = {
      "reference_number": this.refNum
    }
    console.log('object sent to API', toSendToAPI)

    this.atfService.fetchingclaimedandverifiedform(toSendToAPI).subscribe(
      response =>{
        console.log('CLAIMED FORM DATA', response[0])
        this.filledformdatawithbankdetails = response[0]
      },
      error => {
        console.log('ERROR WHEN FETCHING CLAIM FORM DATA', error)
      }
    )
  }

}