import { Component, OnInit } from '@angular/core';
import { AtfservicesService } from '../services/atfservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-applicantfilledforms',
  templateUrl: './applicantfilledforms.component.html',
  styleUrls: ['./applicantfilledforms.component.css']
})
export class ApplicantfilledformsComponent implements OnInit {

  formdata;
  user_id;
  preloadingPage:boolean=true;

  constructor(private atfService: AtfservicesService, private router:Router) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('uuid')
    this.fetchingmyapplications()
  }

  // fetching all applications (approved/pending/rejected) for single user/applicant
  fetchingmyapplications(){
        
    this.atfService.getsingleuserapplications(this.user_id).subscribe(
      response =>{
        this.preloadingPage=false;
        this.formdata = response.response;
        // console.log('formdata at myapplicationsview.ts', this.formdata)
      },
      error =>{
        console.log(error);
      }
    )
  }

  goToFilledForm(data) {
    this.router.navigate(['/bankviewfilledform'], {state: {formData: data}})
  }

  goToFilledDoc(data) {
    this.router.navigate(['/benviewapproved-forms'], {state: {formData: data}})
  }


}
