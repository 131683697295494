import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthserviceService } from "../services/authservice.service";
import { Role } from "../_models";
import { AtfservicesService } from '../services/atfservices.service';

@Component({
  selector: 'app-approvedandverifiedform',
  templateUrl: './approvedandverifiedform.component.html',
  styleUrls: ['./approvedandverifiedform.component.css']
})
export class ApprovedandverifiedformComponent implements OnInit {

  filledformdata;
  currentUser;
  date_approved_and_verified; 


  constructor(private authService: AuthserviceService, private atfService: AtfservicesService, private router: Router) {

    if (this.router.getCurrentNavigation().extras.state == undefined || null) {
      console.log('I AM HERE ONE XXXXXXXX===========================')

      this.router.navigate(["/approvedforms"]);
    } else {
      console.log('I AM HERE TWO YYYYYY===========================')
      this.filledformdata = this.router.getCurrentNavigation().extras.state.formData;
      console.log('THREE ZZZZZZZZZZZ===========================', this.filledformdata)
    }


    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    console.log(this.currentUser, "benview current user");
    console.log(this.filledformdata);
    this.checkTypeOfCurrentUser();
    this.date_approved_and_verified = new Date().toDateString();
  }

  checkTypeOfCurrentUser() {
    if (typeof this.currentUser === "string") {
      this.currentUser = JSON.parse(this.currentUser);
      return;
    }
  }

  // getting beneficiary role to enable display of signatory section and verify-button for beneficiary-user-type
  get isBeneficiary() {
    return this.currentUser && this.currentUser.user_type === Role.Beneficiary;
  }

  // getting Super-Admin role to enable display of verify-button for Super-Admin-user-type
  get isSuperAdmin() {
    return this.currentUser && this.currentUser.user_type === Role.Super_Admin;
  }

}
