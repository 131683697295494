import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthserviceService } from '../services/authservice.service';
import { Location } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authenticationService: AuthserviceService, private location: Location) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;

        // console.log('currentUser AT AUTHGUARD.TS b4 if statement', currentUser)
        // console.log('TYPE OF currentUser AT AUTHGUARD.TS b4 if statement', typeof(currentUser))

        if (currentUser) {

            if(typeof(currentUser)==='string') {
                
                let object = JSON.parse(currentUser)

                // console.log('expecting object converted from string')
                // console.log('currentUser AT AUTHGUARD.TS', object)
                // console.log('TYPE OF currentUser AT AUTHGUARD.TS', typeof(object))


                 // check if route is restricted by role
                if (route.data.roles && route.data.roles.indexOf(object.user_type) === -1) {
                    // role not authorised so redirect to home page
                    // this.router.navigate(['/login']);
                    this.location.back();
                    return false;
                }
            // authorised so return true
            return true;

            } 
            else {
                if(typeof(currentUser)==='object') {
                    
                    // check if route is restricted by role
                    if (route.data.roles && route.data.roles.indexOf(currentUser.user_type) === -1) {
                        // role not authorised so redirect to home page
                        // this.router.navigate(['/fileupload']);
                        // redirect to previous page
                        this.location.back();
                        return false;
                    }
                // authorised so return true
                return true;
                }
            return;
            
            }   
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}