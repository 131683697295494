import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from '../services/authservice.service';
import { Role } from '../_models';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent implements OnInit {

  // data stored on local storage upon login using login.ts 
  username;
  user_type;

  //  data stored on local storage using authservice
  currentUser;

  constructor(private authService: AuthserviceService, private router: Router) { 
    this.currentUser = JSON.parse(JSON.stringify(this.authService.currentUserValue));
  }

  ngOnInit() {

    this.checkTypeOfCurrentUser()

    // console.log('currentUser AT NAVBAR.TS on init', this.currentUser)
    // console.log('TYPE OF currentUser AT NABAR.TS on init', typeof(this.currentUser))

    this.username = localStorage.getItem('username')
    this.user_type = localStorage.getItem('user_type')
  }

  checkTypeOfCurrentUser(){
    if(typeof(this.currentUser)==='string') {
      this.currentUser = JSON.parse(this.currentUser)
      return;
    }
  }

  // getting Guarantee Applicant role to enable display of nav-links for guarantee-applicant-user-type
  get isGuaranteeApplicant() {
    return this.currentUser && this.currentUser.user_type === Role.Guarantee_Applicant;
  }

  // getting Signatory role to enable display of nav-links for signatory-user-type
  get isSignatory() {
    return this.currentUser && this.currentUser.user_type === Role.Signatory;
  }
  // getting APPLICANT-LEVEL signatory one role to enable display of nav-links for signatoryOne-user-type
  get isApplicantSignatory1() {
    return this.currentUser && this.currentUser.user_type === Role.Application_Signatory_1;
  }

  // getting APPLICANT-LEVEL signatory two role to enable display of nav-links for signatoryTwo-user-type
  get isApplicantSignatory2() {
    return this.currentUser && this.currentUser.user_type === Role.Application_Signatory_2;
  }



  // getting bank/banker role to enable display of nav-links for bank-user-type
  get isBanker() {
    return this.currentUser && this.currentUser.user_type === Role.Bank;
  }

  // getting Bank TradeOps role to enable display of nav-links for bankTradeOps-user-type
  get isBankTradeOps() {
    return this.currentUser && this.currentUser.user_type === Role.Bank_TradeOps;
  }

  // getting Bank Authorized Signatory ONE role to enable display of nav-links for for bankSigOne-user-type
  get isBankAuthorizedSignatory1() {
    return this.currentUser && this.currentUser.user_type === Role.Bank_Authorized_Signatory_1;
  }

  // getting Bank Authorized Signatory TWO role to enable display of nav-links for for bankSigTwo-user-type
  get isBankAuthorizedSignatory2() {
    return this.currentUser && this.currentUser.user_type === Role.Bank_Authorized_Signatory_2;
  }

  // getting beneficiary role to enable display of nav-links for ben-user-type
  get isBeneficiary() {
    return this.currentUser && this.currentUser.user_type == Role.Beneficiary
  }

  // getting Super-Admin role to enable display of nav-links for Super-Admin-user-type
  get isSuperAdmin() {
    return this.currentUser && this.currentUser.user_type === Role.Super_Admin;
  }


  onLogout() {
    window.localStorage.clear();
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    }); 
  }

}
