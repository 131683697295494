import { Role } from "./role";

export class User {
    firstname: string;
    lastname: string;
    email: string;
    company_name:string; 
    // password: string; 
    user_type: string;
    user_id: string;
    role: Role;
    token?: string;
}


    