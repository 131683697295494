import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// authguards and user-types
import { AuthGuard } from './_helpers';
import { Role } from './_models';

// components
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { FormdatatableComponent } from './formdatatable/formdatatable.component';
import { BankviewfilledformComponent } from './bankviewfilledform/bankviewfilledform.component';
import { BenviewapprovedlistComponent } from './benviewapprovedlist/benviewapprovedlist.component';
import { BenviewprocessedformComponent } from './benviewprocessedform/benviewprocessedform.component';
import { ApprovedandverifiedformComponent } from './approvedandverifiedform/approvedandverifiedform.component';
import { VerifiedandclaimedformComponent } from './verifiedandclaimedform/verifiedandclaimedform.component';
import { ApplicantfilledformsComponent } from './applicantfilledforms/applicantfilledforms.component';
import { SuperadminloginComponent } from './superadminlogin/superadminlogin.component';


const routes: Routes = [

  { path: 'register',
  component: RegisterComponent
  },

  { path: 'login',
  component: LoginComponent
  },

  { path: 'fileupload',
  component: FileuploadComponent,
  canActivate: [AuthGuard]
  },

  { path: 'my-applications',
  component: ApplicantfilledformsComponent,
  canActivate: [AuthGuard]
  },

  { path: 'formdata',
  component: FormdatatableComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Bank, Role.Application_Signatory_1, Role.Application_Signatory_2, Role.Bank_TradeOps, Role.Bank_Authorized_Signatory_1, Role.Bank_Authorized_Signatory_2, Role.Super_Admin] }

  },

  { path: 'bankviewfilledform',
  component: BankviewfilledformComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Bank, Role.Bank_TradeOps, Role.Bank_Authorized_Signatory_1, Role.Bank_Authorized_Signatory_2, Role.Guarantee_Applicant, Role.Signatory, Role.Application_Signatory_1, Role.Application_Signatory_2, Role.Super_Admin] }

  },

  { path: 'approvedforms',
  component: BenviewapprovedlistComponent,
  canActivate: [AuthGuard],
  data: { roles: [Role.Beneficiary, Role.Super_Admin] }
  },

  { path: 'benviewapproved-forms',
  component: BenviewprocessedformComponent,
  canActivate: [AuthGuard],
  },

  { path: 'approvedandverifiedforms',
    component: ApprovedandverifiedformComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Beneficiary, Role.Super_Admin] }
  },

  { path: 'verifiedandclaimedforms',
    component: VerifiedandclaimedformComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Beneficiary, Role.Super_Admin] }
  },

  { path: 'super-admin-login',
  component: SuperadminloginComponent
  },

 // second-last path
  { path: '',
  component: LoginComponent
  },

  // last path
  { path: '**',
  component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
