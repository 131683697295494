import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AtfservicesService } from '../services/atfservices.service';
import { AuthserviceService } from '../services/authservice.service';
import { Role } from '../_models';

@Component({
  selector: 'app-bankviewfilledform',
  templateUrl: './bankviewfilledform.component.html',
  styleUrls: ['./bankviewfilledform.component.css']
})
export class BankviewfilledformComponent implements OnInit {

  filledformdata;
  reference_number;
  ifapproved: boolean = false;
  ifrejected: boolean = false;
  applevelsigoneprocessedformdata;
  applevelsigtwoprocessedformdata
  date_processed;
  currentUser;

   // disable signatory areas based on the authenticated applicant-level-signatory-user-type
   disableSignatoryOneSection: boolean = false;
   disableSignatoryTwoSection: boolean = false;



  constructor(private authService: AuthserviceService, private atfService: AtfservicesService, private router: Router) {

    if(this.router.getCurrentNavigation().extras.state == undefined || null) {
      this.router.navigate(['/formdata'])
    }
    else {
      this.filledformdata = this.router.getCurrentNavigation().extras.state.formData;
      console.log('FILLEDFORMDATA ====================', this.filledformdata)
    }

    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    
    this.checkTypeOfCurrentUser();
    this.reference_number = this.filledformdata.reference_number;
    
    // application/applicant level ---signatoryOneData
    this.applevelsigoneprocessedformdata = {
      reference_number: this.reference_number, 
      status: "Approved at Applicant-Level: SignatoryOne-Approval",
      applicant_level_signatory_1_name: this.filledformdata.applicant_level_signatory_1_name,
      applicant_level_signatory_1_signature: this.filledformdata.applicant_level_signatory_1_signature, 
    }

    // application/applicant level ---signatoryTwoData
    this.applevelsigtwoprocessedformdata = {
      reference_number: this.reference_number, 
      status: "Approved at Applicant-Level: SignatoryTwo-Approval",
      applicant_level_signatory_2_name:this.filledformdata.applicant_level_signatory_2_name,
      applicant_level_signatory_2_signature: this.filledformdata.applicant_level_signatory_2_signature, 
    }
     
  }

  checkTypeOfCurrentUser(){
    if(typeof(this.currentUser)==='string') {
      this.currentUser = JSON.parse(this.currentUser)
      return;
    }
  }

  
   // getting bank/banker role to enable display of form-processing bit for bank-user-type
   get isBank() {
    return this.currentUser && this.currentUser.user_type === Role.Bank;
  }

  // --- old version: getting signatory role to enable display of form-processing bit for signatory-user-type
  get isSignatory() {
    return this.currentUser && this.currentUser.user_type === Role.Signatory;
  }

  // getting APPLICANT-LEVEL signatory one role to enable display of form-processing bit for signatory-user-type
  get isApplicantSignatory1() {
    this.disableSignatoryTwoSection = true;
    this.disableSignatoryOneSection = false;
    return this.currentUser && this.currentUser.user_type === Role.Application_Signatory_1;
  }

  // getting APPLICANT-LEVEL signatory two role to enable display of form-processing bit for signatory-user-type
  get isApplicantSignatory2() {
    this.disableSignatoryOneSection = true;
    this.disableSignatoryTwoSection = false;
    return this.currentUser && this.currentUser.user_type === Role.Application_Signatory_2;
  }

  // getting Super-Admin role to enable display of form-processing for Super-Admin-user-type
  get isSuperAdmin() {
    return this.currentUser && this.currentUser.user_type === Role.Super_Admin;
  }

  // section 7 functions ---applicant-level signatories
  sigOneAuthorize() {
    console.log(this.applevelsigoneprocessedformdata)
    this.atfService.applicantleveloneformprocessing(this.applevelsigoneprocessedformdata).subscribe(
      (response) => {
        // console.log('applicant level sig one res=================: ', response)
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Form submitted successfully',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.router.navigate(["/formdata"]);
        });
      },
      (error)=>{
        console.log('applicant level sig one err: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Ensure form is correctly signed",
        }).then(() => {
          window.location.reload();
        });
      });
  
  }

  sigTwoAuthorize() {
    console.log('sig two data================================', this.applevelsigtwoprocessedformdata)
    this.atfService.applicantleveltwoformprocessing (this.applevelsigtwoprocessedformdata).subscribe(
      (response) => {
        console.log('applicant level sig two res-------------------: ', response);
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'Form submitted successfully',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.router.navigate(["/formdata"]);
        });
      },
      (error)=>{
        console.log('applicant level sig two err: ', error)
        Swal.fire({
          icon: "error",
          title: "An error occured",
          text: "Ensure form is correctly signed",
        }).then(() => {
          window.location.reload();
        });
      });
  }

}
