import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthserviceService } from '../services/authservice.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  logindata;
  loading: boolean = false;
  b4loading: boolean = true;
  loginPage: boolean = true;
  preloadingPage:boolean=false;
  returnUrl: string;


  constructor(
    private authService: AuthserviceService,
    private route: ActivatedRoute,
    private router: Router
    ) {
    // redirect to home page if already logged in
    if(this.authService.currentUserValue){
      // console.log('LOGIN.TS AUTHSERVICECURRENTUSERVALUE=======', this.authService.currentUserValue)
      this.router.navigate(['/fileupload'])
    }
   }

  ngOnInit() {
    this.logindata = {
      email: '',
      password: '',
      user_type: ''
    };

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  loginUser() {

    // console.log(this.logindata);

    this.loading = true;
    this.b4loading = false;

    this.authService.login(this.logindata).subscribe(
      response => {
        // console.log('LOGIN.TS: LOGGIN response FROM AUTHSUBSCRIPTION', response)
               
        if(response.code == 204) {

          this.b4loading = true;
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'An Error Occured',
            text: 'Email and password do not match. Try again',
          }).then(() =>{
            window.location.reload();
          });
        }
        else if(response.code == 206) {
          this.b4loading = true;
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'An Error Occured',
            text: 'Email with that user-type does not exist. Try again',
          }).then(() =>{
            window.location.reload();
          });

        }
        else {
          console.log('my user data=================', response.results[0])
          localStorage.setItem('currentUserDetails', JSON.stringify(response.results[0]))

          localStorage.setItem('uuid', response.results[0].user_id)
          localStorage.setItem('username', response.results[0].firstname)
          localStorage.setItem('user_type', response.results[0].user_type)

          this.loading = false;
          this.loginPage = false;
          this.preloadingPage = true;
          let authorisedusertype = response.results[0].user_type
          console.log('USER TYPE THAT IS LOGGIN IN: ===', typeof(authorisedusertype), authorisedusertype)
          setTimeout(() => {
            if(authorisedusertype=='Guarantee Applicant' || authorisedusertype=='Signatory') {
              this.router.navigate(['/fileupload']);
            }
            else if(authorisedusertype=='Bank'|| authorisedusertype=='Application Signatory 1'|| authorisedusertype=='Application Signatory 2' || authorisedusertype=='Bank TradeOps' || authorisedusertype=='Bank Authorized Signatory 1' || authorisedusertype=='Bank Authorized Signatory 2') {
              this.router.navigate(['/formdata']);
            }
            else if(authorisedusertype=='Beneficiary') {
              this.router.navigate(['/approvedforms']);
            }            
          }, 3000);
        } 
      },
      error => {
        console.log(error)
        
        this.b4loading = true;
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'An Error Occured',
          text: 'Try again',
        }).then(() =>{
          window.location.reload();
        });
      }
    )

  }
}
